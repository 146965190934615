<template>
  <div class="page-race">
    <div class="container">
      <fil-ariane :navigation="navigation"></fil-ariane>

      <h1>
      {{ getRaceLabel(getRaceById(dogs[0].race_id)) }}

      </h1>
      <h2>{{ $tc("race.dog", this.dogs.length, { count: this.dogs.length }) }}</h2>

      <RewardLogo class="reward" v-if="showRewardsLink()" />
      <router-link :to="{ name: 'reward', params: {race_id: dogs[0].race_id} }"
        class="link-to-rewards"
        v-if="showRewardsLink()" v-html="$t('race.go_to_rewards')" >
      </router-link>

      <detail-classe
          v-for="list of dogsByClassAndResults"
          :key="list.key"
          :dogs="list.dogs"
          :classname="list.name"></detail-classe>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import DetailClasse from '@/components/DetailClasse.vue';
import FilAriane from '@/components/FilAriane.vue';

import dogMixin from '../common/dog.mixin';
import RewardLogo from '../assets/reward.svg';

export default {
  name: 'race',
  mixins: [dogMixin],
  components: { DetailClasse, FilAriane, RewardLogo },
  computed: {
    ...mapGetters([
      'getDogsByRaceId',
      'getDogsByClassAndSex',
      'getRaceById',
      'currentUser',
      'currentLang',
      'getDogResult',
      'getClassById',
    ]),
    dogsByClassAndResults() {
      return this.getDogsByClassAndSex(this.id, this.currentLang);
    },
    dogs() {
      return this.getDogsByRaceId(this.id);
    },
    dogsToEvaluate() {
      return this.dogs.filter((d) => d.qualificatif_id === ''
        && this.getClassById(d.classe_id).qualificatifs.length > 0);
    },
    navigation() {
      return [
        { name: this.$i18n.t('breadcrumb.shows', { name: this.currentUser.nom }), link: '/expos' },
        { name: this.getRaceLabel(this.getRaceById(this.dogs[0].race_id)), link: '' },
      ];
    },
  },
  props: {
    id: { type: String },
    dog_id: { type: String },
  },
  mounted() {
    if (this.dog_id) {
      const dogPos = document.querySelector(`[data-dog-id="${this.dog_id}"]`);
      if (dogPos) {
        dogPos.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        dogPos.classList.add('origin');
      }
    }
  },
  methods: {
    getClassMessage(clz) {
      return clz;
    },
    hasResult(dog) {
      return this.getDogResult(dog.id);
    },
    showRewardsLink() {
      return this.dogsToEvaluate.length < this.dogs.length;
    },
  },
};
</script>

<style lang="scss">
  .page-race {
    h1 {margin-bottom: 0;}
    h2 {text-align: center; color: #635959; font-size: 1.8rem; text-transform: uppercase;
      margin-top: 0;}
    .reward {width:30px}
    .link-to-rewards {font-size: min(1.5rem, 5vw)}
    //.link-to-rewards:before{content: ' > '}
  }

</style>
