<template>
  <div class="select-position" v-bind:class="{ disabled: isLocked }">
    <div v-for="p of positions" class="pos"
      v-bind:class="{ active: p === pos }"
      v-html="p" :key="p" :data-pos="p"
      @click="onClick">
    </div>
  </div>
</template>

<script>
export default {
  name: 'select-position',
  props: {
    pos: { type: [String, Number] },
    isLocked: { type: Boolean },
    max_pos: Number,
  },
  data() {
    return {
      positions: [],
    };
  },
  mounted() {
    this.positions = [...Array(this.max_pos).keys()].map((i) => i + 1);
  },
  methods: {
    onClick(e) {
      if (this.isLocked) {
        return;
      }
      const newPos = e.target.dataset.pos;
      this.$emit('position-changed', { from_pos: '', to_pos: newPos });
    },
  },
};
</script>

<style lang="scss">
  .select-position {
    display: flex; justify-content: center;
    &.disabled {
      :not(.active) {opacity: .2}
      .active {opacity: 1; background: white}
    }

    .pos {
      user-select: none;
      flex: 0 50px;text-align: center; color: #38A2A1; font-weight: 400;
      border:1px #CCC solid; line-height: 2rem; margin: 0; padding: 1px;
      &.active {
        //border-radius: 30px;
        padding: 0; border:2px darkred solid; background: #F5F5F5;
      }
      &:hover {background: #DDD; cursor: pointer}
    }
    button {
      width:1.5rem; height:1.5rem; display: inline-block;background-size: contain; border:0;
      margin:auto; background-color: #a2e7e6;
      &.disabled {visibility: hidden}
      &:hover {  background-color: #b6f1f1; cursor: pointer; }
    }
  }
</style>
