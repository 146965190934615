<template>
  <div class="detail-classe" >
    <h3 :class="getClass">
      <div class="grouping">
        <div class="title" v-html="classname"></div>
        <div class="hours" v-html="getStartingHour"></div>
      </div>
      <button v-if="canEdit && !isEdited" @click="onChangeEdit">
        <font-awesome-icon icon="edit"/>
      </button>
    </h3>
    <div class="chien" :data-dog-id="dog.nr_cat" v-for="dog in dogs"
      :set="result = getResult(dog)" :key="dog.nr_cat">
        <div class="ref">
          <router-link :to="{ name: 'chien', params: {id: dog.nr_cat} }">
          {{ getFormatedIdentifier(dog) }}
          </router-link>
        </div>
        <div class="nr-cat">
          <router-link :to="{ name: 'chien', params: {id: dog.nr_cat} }">
            {{ dog.nr_cat }}
          </router-link>
        </div>
        <div class="logos">
<!--          <font-awesome-icon icon="comment" v-if="dog.commentaire"/>-->
          <CommentLogo v-if="dog.commentaire" class="comment">
            <title v-html=dog.commentaire></title>
          </CommentLogo>
          <font-awesome-icon icon="certificate" v-if="dog.recompenses"
              :title="getTranslatedRewards(dog.recompenses)" />
        </div>
        <div class="position" v-if="!isEdited" >
          <select-position :pos="parseInt(result.classement)" :max_pos="result.posMax"
            v-on:position-changed="onPositionChanged($event, dog)"
            v-show="result.canChangePosition"
            :is-locked="isLocked"
          />
        </div>
        <div class="resultats" v-if="!isEdited ">
          {{ result.qualifier }}
        </div>
        <div class="actions" v-if="isEdited">
          <select-qualification :qualifiers="getClassQualifiers"
          :value="result.qualifier_id || ''" v-on:value-changed="onEditValueChange($event, dog)" />
        </div>
      </div>
<!--    </div>-->
    <div class="actions">
      <button class="action cancel" v-if="isEdited" @click="onCancel">
        {{ $t('global.reset') }}
      </button>
      <button class="action submit" v-if="isEdited" @click="onSubmit">
        {{ $t('global.submit') }}
      </button>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';

import SelectPosition from '@/components/SelectPosition.vue';
import SelectQualification from '@/components/SelectQualification.vue';
import ErrorsMixin from '@/common/errors.mixin';

import { CHANGE_POSITION, EVALUATE_DOG } from '../store/const/actions.type';
import CommentLogo from '../assets/comment.svg';

export default {
  name: 'detail-classe',
  components: {
    SelectQualification,
    SelectPosition,
    CommentLogo,
  },
  mixins: [ErrorsMixin],
  computed: {
    ...mapGetters([
      'getDogResult',
      'currentUser',
      'currentLang',
      'getDogResult',
      'getQualifierById',
      'getClassById',
      'getClassByLabel',
    ]),
    getClass() {
      if (this.dogs.length === 0) {
        return this.clazz; // default value
      }
      // const firstQualifId = Object.keys(this.dogs)[0];
      // return this.dogs[firstQualifId][0].nr_sexe === '1' ? 'male' : 'female';
      return this.dogs[0].nr_sexe === '1' ? 'male' : 'female';
    },
    getStartingHour() {
      const hr = this.dogs[0].heure_passage_classe;
      return hr !== 'N.C' && hr ? `(${hr})` : '';
    },
    getClassQualifiers() {
      if (this.dogs.length === 0) {
        return this.clazz; // default value
      }
      const classId = this.dogs[0].classe_id;
      const clazz = this.getClassById(classId);
      return clazz.qualificatifs.map((q) => ({ id: q.id, fr: q.lib_fr, en: q.lib_en }));
    },
    canEdit() {
      return this.getClassQualifiers.length > 0;
    },
  },
  props: {
    classname: {},
    dogs: {},
    clazz: null,
  },
  data() {
    return {
      isEdited: false,
      isLocked: false,
      editValues: {},
    };
  },
  mounted() {
    this.isLocked = this.dogs.some((d) => d.recompenses);
  },
  methods: {
    getFormatedIdentifier(dog) { // ABC DEF GHI ...
      return dog.identifiant.match(/.{1,3}/g).join(' ');
    },
    getTranslatedRewards(rewards) {
      return rewards.split(',').map((r) => this.$t(`rewards.${r}`)).join(', ');
    },
    getResult(dog) {
      // console.log('getResult', dog);
      const result = this.getDogResult(dog.nr_cat);
      if (!result) {
        return {
          qualifier: '-',
        };
      }

      result.qualifier_id = result.qualifier;
      const dogClass = this.getClassByLabel(this.classname, this.currentLang);
      if (dogClass) {
        const qualifier = this.getQualifierById(dogClass.id, result.qualifier_id);
        result.qualifier = ' ? ';
        // console.log(qualifier, result.qualifier_id);
        if (qualifier) {
          result.qualifier = this.currentLang === 'en' ? qualifier.lib_en : qualifier.lib_fr;
          if (qualifier.nb_Classement === '0') {
            result.canChangePosition = false;
          } else {
            result.canChangePosition = (result.posMax > 0);
          }
        }
      }
      return result;
    },
    onChangeEdit() {
      this.isEdited = !this.isEdited;
    },
    onCancel() {
      this.isEdited = false;
    },
    onSubmit() {
      this.isEdited = false;
      Object.keys(this.editValues).forEach((k) => {
        const data = this.editValues[k];
        this.$store.dispatch(EVALUATE_DOG, data)
          .catch((err) => this.handleError(err));
      });
    },
    onPositionChanged(event, dog) {
      const classDogs = Object.values(this.dogs).flat();
      const otherDog = classDogs.find((d) => d.classement === event.to_pos
          && d.nr_cat !== dog.nr_cat);
      this.$store.dispatch(CHANGE_POSITION, {
        nr_cat: dog.nr_cat, from_pos: event.from_pos, to_pos: event.to_pos,
      }).catch((err) => this.handleError(err));

      if (otherDog) {
        this.$store.dispatch(CHANGE_POSITION, {
          nr_cat: otherDog.nr_cat, from_pos: event.to_pos, to_pos: '',
        }).catch((err) => this.handleError(err));
      }
    },
    onEditValueChange(event, dog) {
      this.editValues[dog.nr_cat] = {
        dog,
        comment: dog.commentaire,
        qualifier: event.qualifier_id,
      };
    },
  },
};
</script>

<style lang="scss">
  .detail-classe {
    h3 {
      background-color: #EEE; height:2rem; line-height: 2rem; border: 1px gray solid;
      display: flex;
      &.male {background-color: #A0CFD6}
      &.female {background-color: #E6A9A9}
      padding:.5rem 1rem; display: flex;
      .grouping {flex: 0 200px;
        .title {}
        .hours {text-align: left; color: #00A; font-size: .6rem}
      }
      button {
        flex: 1 100px; text-align: right; margin: 0 1rem; width: 3rem; border: none;
        background-color: transparent;
        &:hover { cursor: pointer; color:#6792bc;}
      }
    }

    @keyframes animate-color {
      0% { background: #efe5a5}
      100% {background: transparent}
    }

    .chien {
      display:flex; flex-direction: row; font-size: 1rem; line-height: 2rem;
      margin-bottom: .5rem; padding: 0 .5rem;
      align-items: center;
      &.origin, &.origin > * {animation: animate-color 1.5s ease-out}

      .ref {flex:0 200px; max-width: 25%;
        a {font-weight: normal !important; font-size: .9rem}
      }
      .nr-cat {
        flex: 0 50px; padding:.3rem; text-align: right;
        a {font-weight: 900 !important; font-size: 1.3rem; border: 1px black solid; padding: .4rem;
        background-color: white}
        a:hover {background-color: #DDD}
      }
      .logos {
        padding:0 .5rem; flex: 0 60px; display: flex; gap: .5rem;
        .fa-comment {color: #ffA000;}
        .fa-certificate {color: darkgreen}
      }
      .position {flex: 1 200px; max-width: 25%;}
      .resultats, .action {
        flex: 0 100px; text-align: center; line-height: 1.5rem;
        font-size: min(1rem, 3vw);
      }
      .actions {
        height: 2rem; flex: 1 200px; justify-content: end; display: flex;
        option {font-size: .8rem; margin-left: auto;}
      }
    }

    > .actions {
      margin-top:1rem; display: flex; justify-content: flex-end;
      .cancel {margin-right:2rem;}
    }

    .comment  {
      width: 50%;
        > * {fill: #FFA000}
      }
    .rewards {
      width: 50%;
      > * {fill: #FFA000}
    }
  }
</style>
