<template>
  <div class="select-qualification">
    <select @change="onChange" v-model="selectedValue">
      <option value="">{{ $t('global.choose_one') }}</option>
      <option v-for="qualifier of qualifiers" :value="qualifier.id" :key="qualifier.id"
        :selected="selectedValue === qualifier.id"
        v-html="getTranslatedOption(qualifier)"></option>
    </select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'select-qualification',
  computed: {
    ...mapGetters([
      'getDogResult',
      'currentUser',
    ]),
  },
  props: {
    qualifiers: Array,
    value: String,
  },
  mounted() {
    this.selectedValue = this.value;
  },
  methods: {
    getTranslatedOption(option) {
      if (!option) {
        console.error('option is invalid : ');
      }
      if (this.currentUser.lang === 'fr') {
        return option.fr;
      }
      if (this.currentUser.lang === 'en' && option.en !== '') {
        return option.en;
      }
      console.warn('language/user is invalid', this.currentUser.lang, option);
      return option.fr;
    },
    onChange() {
      this.$emit('value-changed', { qualifier_id: this.selectedValue });
    },
  },
  data() {
    return {
      selectedValue: null,
    };
  },
};
</script>

<style lang="scss">
  .select-qualification {
  }
</style>
